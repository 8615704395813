import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { LinkButton } from '@hundred5/design-system';

import { ConfirmModal } from '@/features/common';
import { GoogleRecaptchaPrivacyDisclaimer } from '@/features/google-recaptcha';
import { useMeQuery } from '@/features/me';
import { useDeleteMe, useExportMe } from '@/hooks/me';

export function ProfileActions() {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [exportMe, exporting] = useExportMe();
  const deleteMe = useDeleteMe();
  const meQuery = useMeQuery();

  const isOwnerOfAnyWorkspace = useMemo(
    () =>
      meQuery.data?.workspaces?.some(workspace => workspace.role === 'owner'),
    [meQuery.data?.workspaces]
  );

  const openDeleteModal = useCallback(() => setDeleteModalOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(false), []);

  return (
    <ProfileActionsContainer>
      <LinkButton onClick={() => !exporting && exportMe()}>
        {exporting ? 'Exporting...' : 'Export profile data'}
      </LinkButton>
      <GoogleRecaptchaPrivacyDisclaimer />
      {!isOwnerOfAnyWorkspace ? (
        <>
          <LinkButton onClick={openDeleteModal}>Delete profile</LinkButton>

          <ConfirmModal
            open={deleteModalOpen}
            action="Delete"
            confirmation="delete"
            onConfirm={deleteMe}
            onClose={closeDeleteModal}
          >
            <h3>Are you sure?</h3>
            <p>
              Your user account is going to be deleted permanently with no
              backups. Therefore be sure you really wish to do that.
            </p>
          </ConfirmModal>
        </>
      ) : null}
    </ProfileActionsContainer>
  );
}

const ProfileActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
