import { useCallback } from 'react';

import * as api from '@/api/authentication';
import { useFlashMessages } from '@/features/flash-messages';
import { useApiError } from '@/hooks/api';
import { useAsync } from '@/hooks/async';

interface PasswordUpdateResult {
  success: boolean;
  error?: Error;
}

type updatePasswordFn = (
  oldPassword: string,
  newPassword: string
) => Promise<PasswordUpdateResult>;

export function usePasswordUpdate(): [updatePasswordFn, boolean] {
  const handleApiError = useApiError();
  const { showFlashMessage } = useFlashMessages();

  const updatePasswordCallback = useCallback(
    async (oldPassword: string, newPassword: string) => {
      try {
        await api.updatePassword(oldPassword, newPassword);
        return { success: true };
      } catch (error) {
        if (error.type === 'InvalidPassword') {
          showFlashMessage({ type: 'password_update_error', error });
        } else {
          handleApiError(error);
        }
        return { success: false, error };
      }
    },
    [handleApiError, showFlashMessage]
  );

  const [updatePassword, { loading }] = useAsync(updatePasswordCallback);

  return [updatePassword, loading];
}
