import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { BillingPlan, useSubscribeModal } from '@/features/billing';
import { useHistory, useWorkspaceId } from '@/hooks/router';

interface UpgradeBannerProps {
  icon: React.ReactNode;
  title: string;
  caption: string;
  requiredPlan?: BillingPlan;
  upgradeButtonVisible?: boolean;
  onUpgrade?: () => void;
}

export function UpgradeBanner({
  icon,
  title,
  caption,
  onUpgrade,
  requiredPlan,
  upgradeButtonVisible = true,
}: UpgradeBannerProps) {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const { openSubscribeModal } = useSubscribeModal();

  const handleOnClick = useCallback(() => {
    onUpgrade?.();
    history.push(`/admin/${workspaceId}/settings/billing`);
    requiredPlan && openSubscribeModal(requiredPlan);
  }, [history, onUpgrade, openSubscribeModal, requiredPlan, workspaceId]);

  return (
    <Container>
      <div>
        <div>{icon}</div>
        <div>
          <h3>{title}</h3>
          {caption}
        </div>
      </div>
      {upgradeButtonVisible && (
        <DSButton
          variant="primary"
          size="small"
          type="button"
          onClick={handleOnClick}
        >
          Upgrade
        </DSButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  &,
  div:first-of-type {
    display: flex;
    gap: 24px;
  }
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 22px 36px;
  background: ${props => props.theme.colors.yellow[50]};
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.github};
  z-index: 100;

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  @media all and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 20px;
    padding: 22px 12px;

    button {
      margin: auto;
    }
  }

  @media all and (min-width: 769px) {
    &,
    div:first-of-type {
      gap: 36px;
    }
  }
`;
