import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';
import { Form, Formik, FormikHelpers } from 'formik';

import { FormikInputField, PromptIfDirty } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useMeMutation, useMeQuery } from '@/features/me';

import { usePasswordUpdate } from './personal-info.hooks';
import {
  createUserAttributes,
  FormValues,
  schema,
} from './personal-info.utils';

export function PersonalInfo() {
  const meQuery = useMeQuery();
  const meMutation = useMeMutation();
  const initialValues = useMemo(() => createUserAttributes(meQuery.data), [
    meQuery.data,
  ]);
  const { showFlashMessage } = useFlashMessages();
  const [updatePassword] = usePasswordUpdate();
  const [updatingEmail, setUpdatingEmail] = React.useState(false);
  const [updatingPassword, setUpdatingPassword] = React.useState(false);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await meMutation.mutateAsync({
          ...meQuery.data!,
          name: values.name,
          email: values.email,
        });

        actions.setSubmitting(false);
        if (values.email && values.email !== meQuery.data?.email) {
          actions.resetForm();
          showFlashMessage({ type: 'verification_email_sent' });
        }
      } catch (error) {
        actions.setSubmitting(false);
        if (error.type === 'EmailAlreadyTaken') {
          actions.setFieldError('email', 'This email is already taken');
        }
      }
      if (values.oldPassword && values.newPassword) {
        const result = await updatePassword(
          values.oldPassword,
          values.newPassword
        );
        if (result.success) {
          actions.resetForm();
          showFlashMessage({ type: 'saved' });
        }
      }
    },
    [showFlashMessage, updatePassword, meQuery.data, meMutation]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      onReset={() => {
        setUpdatingEmail(false);
        setUpdatingPassword(false);
      }}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
    >
      {({ isSubmitting }) => (
        <Form data-recording-ignore="mask">
          <PromptIfDirty />
          <DSContentBlock>
            <DSContentBlock.Content>
              <FormContainer>
                <FormikInputField
                  name="name"
                  id="personal-info-name"
                  label="Full Name:"
                  required
                />

                {updatingEmail ? (
                  <FormikInputField
                    label="Email:"
                    name="email"
                    id="personal-info-email"
                    required
                  />
                ) : (
                  <FormButton
                    type="button"
                    variant="secondary"
                    onClick={() => setUpdatingEmail(true)}
                  >
                    Change Email
                  </FormButton>
                )}
                {updatingPassword ? (
                  <>
                    <FormikInputField
                      label="Current password:"
                      name="oldPassword"
                      id="password-update-old"
                      type="password"
                      required
                    />

                    <FormikInputField
                      label="New password:"
                      name="newPassword"
                      id="password-update-new"
                      type="password"
                      required
                    />

                    <FormikInputField
                      label="New password again:"
                      name="newPasswordConfirm"
                      id="password-update-confirm"
                      type="password"
                      required
                    />
                  </>
                ) : (
                  <FormButton
                    type="button"
                    variant="secondary"
                    onClick={() => setUpdatingPassword(true)}
                  >
                    Change Password
                  </FormButton>
                )}
              </FormContainer>
            </DSContentBlock.Content>
            <DSContentBlock.Footer>
              <DSButton variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Saving...' : 'Save settings'}
              </DSButton>
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: auto;

  @media all and (min-width: 768px) {
    width: 300px;
  }
`;

const FormButton = styled(DSButton)`
  width: 160px;
`;
