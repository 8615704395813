import { camelizeKeys, decamelizeKeys } from 'humps';

import { TQuestionDifficulty } from '@/features/questions';
import { TestExperienceLevel } from '@/features/test';
import {
  FeedbackScreen,
  Test,
  TestAttributes,
  TestStats,
  TestType,
} from '@/types/h5test';
import { Id } from '@/types/misc';
import { parseAPIData } from '@/utils/parsers';

import api from './api';
import {
  ApiCandidate,
  FetchCandidateResponse,
  normalizeCandidate,
} from './candidates';

type ApiTest = {
  id: number;
  hashedTestId: string;
  workspaceId: number;
  name: string;
  jobOpeningId: number;
  type: TestType;
  screens: {
    welcome: FeedbackScreen;
    passed: FeedbackScreen;
    not_passed: FeedbackScreen;
    no_threshold: FeedbackScreen;
  };
  percentToPass: number;
  autoNextStepThreshold: number | null;
  autoNextStepTemplateId: number | null;
  autoCandidateReminderDays: number | null;
  durationInSeconds: number;
  approximatedDurationInSeconds: number;
  isFromTestGenerator: boolean;
  isSmart: boolean;
  createdAt: string;
  updatedAt: string;
  questionCount: number;
  dynamicSkills: {
    id: number;
    weight: number;
    dynamicQuestionAmount?: number;
    difficulty?: TQuestionDifficulty;
  }[];
  staticSkills: { id: number }[];
  experienceLevel: TestExperienceLevel | '';
  dynamicQuestionAmount: number | null;
  isQuestionRatingDisabled: boolean;
  introductionVideoUrl: string | null;
  backgroundPictureUrl: string | null;
  isPremiumTemplate: boolean;
};

export const parseExistingTest = (test: ApiTest): Test => ({
  id: String(test.id),
  hashedTestId: String(test.hashedTestId),
  workspace: String(test.workspaceId),
  jobOpening: String(test.jobOpeningId),
  name: test.name,
  type: test.type,
  screens: test.screens,
  passPercentage: test.percentToPass,
  autoNextStepThreshold: test.autoNextStepThreshold,
  autoNextStepTemplateId: test.autoNextStepTemplateId,
  autoCandidateReminderDays: test.autoCandidateReminderDays,
  timeLimit: test.durationInSeconds,
  smartTimeLimit: test.approximatedDurationInSeconds,
  fromTestGenerator: test.isFromTestGenerator,
  smart: test.isSmart,
  createdAt: new Date(test.createdAt),
  updatedAt: new Date(test.updatedAt),
  questionCount: test.questionCount,
  dynamicSkills: test.dynamicSkills
    ? test.dynamicSkills.map(skill => ({
        ...skill,
        id: String(skill.id),
      }))
    : [],
  primarySkills: test.dynamicSkills
    ? test.dynamicSkills
        .filter(skill => (test.isSmart ? skill.weight > 1 : true))
        .map(skill => String(skill.id))
    : [],
  secondarySkills: test.dynamicSkills
    ? test.dynamicSkills
        .filter(skill => skill.weight === 1)
        .map(skill => String(skill.id))
    : [],
  staticSkills: test.staticSkills
    ? test.staticSkills.map(skill => String(skill.id))
    : [],
  experienceLevel: test.experienceLevel || null,
  dynamicQuestionAmount: test.dynamicQuestionAmount,
  isQuestionRatingDisabled: test.isQuestionRatingDisabled,
  introductionVideoUrl: test.introductionVideoUrl,
  backgroundPictureUrl: test.backgroundPictureUrl,
  isPremiumTemplate: test.isPremiumTemplate,
});

export const parseNewTest = (test: ApiTest, workspaceId: Id): Test => ({
  id: String(test.id),
  hashedTestId: String(test.hashedTestId),
  workspace: workspaceId,
  jobOpening: String(test.jobOpeningId),
  name: test.name,
  type: test.type,
  screens: test.screens,
  passPercentage: test.percentToPass,
  autoNextStepThreshold: test.autoNextStepThreshold,
  autoNextStepTemplateId: test.autoNextStepTemplateId,
  autoCandidateReminderDays: test.autoCandidateReminderDays,
  timeLimit: test.durationInSeconds,
  smartTimeLimit: test.approximatedDurationInSeconds,
  fromTestGenerator: test.isFromTestGenerator,
  smart: test.isSmart,
  createdAt: new Date(test.createdAt),
  updatedAt: new Date(test.updatedAt),
  questionCount: test.questionCount,
  dynamicSkills: test.dynamicSkills
    ? test.dynamicSkills.map(skill => ({
        ...skill,
        id: String(skill.id),
      }))
    : [],
  primarySkills: test.dynamicSkills
    ? test.dynamicSkills
        .filter(skill => skill.weight > 1)
        .map(skill => String(skill.id))
    : [],
  secondarySkills: test.dynamicSkills
    ? test.dynamicSkills
        .filter(skill => skill.weight === 1)
        .map(skill => String(skill.id))
    : [],
  staticSkills: test.staticSkills
    ? test.staticSkills.map(skill => String(skill.id))
    : [],
  experienceLevel: test.experienceLevel || null,
  dynamicQuestionAmount: test.dynamicQuestionAmount,
  isQuestionRatingDisabled: test.isQuestionRatingDisabled,
  introductionVideoUrl: test.introductionVideoUrl,
  backgroundPictureUrl: test.backgroundPictureUrl,
  isPremiumTemplate: test.isPremiumTemplate,
});

const serializeTest = (
  attributes: TestAttributes | UpdateTestAttributes,
  test: Test | null
) => ({
  name: attributes.name || (test && test.name),
  jobOpeningId: parseInt(
    attributes.jobOpeningId ?? test?.jobOpening ?? '0',
    10
  ),
  type: attributes.type ?? (test && test.type),
  durationInSeconds: attributes.timeLimit ?? (test && test.timeLimit),
  percentToPass: attributes.passPercentage ?? (test && test.passPercentage),
  autoNextStepThreshold:
    attributes.autoNextStepThreshold !== undefined
      ? attributes.autoNextStepThreshold
      : test && test.autoNextStepThreshold,
  autoNextStepTemplateId:
    attributes.autoNextStepTemplateId !== undefined
      ? attributes.autoNextStepTemplateId
      : test && test.autoNextStepTemplateId,
  autoCandidateReminderDays:
    attributes.autoCandidateReminderDays !== undefined
      ? attributes.autoCandidateReminderDays
      : test && test.autoCandidateReminderDays,
  dynamicSkills: (attributes.dynamicSkills || test?.dynamicSkills)?.map(
    skill => ({
      ...skill,
      id: parseInt(skill.id, 10),
      dynamicQuestionAmount: skill.dynamicQuestionAmount || undefined,
    })
  ),
  experienceLevel: attributes.experienceLevel,
  dynamicQuestionAmount: attributes.dynamicQuestionAmount,
  isQuestionRatingDisabled:
    attributes.isQuestionRatingDisabled === undefined
      ? test?.isQuestionRatingDisabled
      : attributes.isQuestionRatingDisabled,
  screens: attributes.screens ?? test?.screens,
  backgroundPictureUrl:
    attributes.backgroundPictureUrl ?? test?.backgroundPictureUrl,
  skills: attributes.selectedSkills
    ? attributes.selectedSkills.map(skill => ({
        ...skill,
        id: parseInt(skill.id, 10),
      }))
    : undefined,
  testGenPositionId:
    attributes.positionId && parseInt(attributes.positionId, 10),
  pipelineStageId: parseInt(attributes.pipelineStageId),
});

export const parseStats = ({
  questionCount,
}: {
  questionCount: number;
}): TestStats => ({
  numberOfQuestions: questionCount,
});

export async function fetchTest(
  testId: Id
): Promise<{ test: Test; testStats: TestStats }> {
  const response = await api.get(`/tests/${testId}`);
  const data = camelizeKeys(response.data) as any;
  return { test: parseExistingTest(data), testStats: parseStats(data) };
}

export async function createTest(
  workspaceId: Id,
  attributes: TestAttributes
): Promise<{ test: Test; testStats: TestStats }> {
  const response = await api.post(
    `/openings/${attributes.jobOpeningId}/test`,
    decamelizeKeys(serializeTest(attributes, null))
  );

  const data = camelizeKeys(response.data) as any;

  return { test: parseNewTest(data, workspaceId), testStats: parseStats(data) };
}

interface UpdateTestAttributes extends TestAttributes {
  smart: boolean;
}

export async function inviteCandidateToTest(
  testId: string,
  candidateId: string,
  email: {
    message: string;
    subject: string;
    resendInvitation: boolean;
    candidateTags?: Id[];
    sendAt?: Date;
  }
): Promise<FetchCandidateResponse> {
  const response = await api.post(
    `/tests/${testId}/invite/${candidateId}`,
    {
      email: {
        subject: email.subject,
        message: email.message,
        resend: email.resendInvitation,
        test_taker_tag_ids: email.candidateTags?.map(id => Number(id)),
        send_at: email.sendAt?.toISOString(),
      },
    },
    {
      captchaAction: 'test_invite',
    }
  );
  return normalizeCandidate(
    candidateId,
    parseAPIData(response.data) as ApiCandidate
  );
}
