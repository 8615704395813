import React from 'react';
import styled from '@emotion/styled';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { parseExistingTest } from '@/api/tests';
import { TId, useDocumentTitle, usePageLayout } from '@/features/common';
import { Loader } from '@/features/common/components/loader';
import { useFlashMessages } from '@/features/flash-messages';
import { useJobOpeningQuery } from '@/features/job-opening';
import {
  LandingPageSettings,
  ResultPageSettings,
  TestEditor,
  TestPageContext,
  TestPageHeader,
  TestSettings,
  useTestQuery,
  VideoIntroduction,
} from '@/features/test';
import { useWorkspace } from '@/features/workspace';
import { useHistory } from '@/hooks/router';

const TestPage = () => {
  const { url } = useRouteMatch();
  const { testId } = useParams<{ testId: TId }>();
  const history = useHistory();
  const { showFlashMessage } = useFlashMessages();
  const pageLayout = usePageLayout();
  const workspace = useWorkspace();

  const {
    data: jobOpening,
    isLoading: isJobOpeningLoading,
  } = useJobOpeningQuery({
    enabled: !!workspace,
    retry: false,
    meta: {
      skipDefaultErrorHandler: true,
    },
    onError: () => {
      showFlashMessage({
        type: 'job_opening_load_error',
        error: 'job_opening_not_found',
      });
      history.push(`/admin/${workspace!.id}/openings`);
    },
  });
  const { data: test, isLoading: isTestLoading } = useTestQuery(
    { testId },
    {
      enabled: !!workspace && !!jobOpening && !!testId,
      retry: false,
      meta: {
        skipDefaultErrorHandler: true,
      },
      onError: () => {
        showFlashMessage({
          type: 'test_load_error',
          error: 'test_not_found',
        });
        // If we were able to make the request we have information about the job opening
        history.push(`/admin/${workspace!.id}/openings/${jobOpening!.id}`);
      },
    }
  );

  useDocumentTitle(test?.name);

  if (isJobOpeningLoading || isTestLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (!workspace || !jobOpening || !test) {
    history.push(`/admin`);
    return null;
  }

  return (
    <TestPageContext.Provider value={{ workspace, jobOpening, test }}>
      <TestPageContainer offsetTop={pageLayout.offsetTop}>
        <TestPageHeader />
        <Content>
          <Switch>
            <Route path={`${url}/settings`}>
              <TestSettings />
            </Route>

            <Route path={`${url}/questions`}>
              <TestEditor />
            </Route>

            <Route path={`${url}/landing-page`}>
              <LandingPageSettings testId={test.id} />
            </Route>

            <Route path={`${url}/results-page`}>
              <ResultPageSettings testId={test.id} />
            </Route>

            <Route path={`${url}/intro`}>
              {/* @ts-ignore TODO: temporary workaround for incompatible interfaces, this is to be removed when single settings screens are refactored */}
              <VideoIntroduction test={parseExistingTest(test)} />
            </Route>
          </Switch>
        </Content>
      </TestPageContainer>
    </TestPageContext.Provider>
  );
};

const TestPageContainer = styled.div<{ offsetTop: number }>`
  max-height: ${({ offsetTop }) => `calc(100svh - ${offsetTop}px)`};
  display: flex;
  flex-direction: column;
`;
const LoaderWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;
const Content = styled.div`
  flex: 1;
  padding: 24px;
  overflow: auto;
`;

export default TestPage;
